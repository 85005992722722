<template>
  <div class="zrproducttypelist">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="breadCrumbItem in breadCrumbList" :key="breadCrumbItem.path">
              {{ breadCrumbItem.meta.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="toCreate">添加</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="adminList" size="mini" border="" style="width: 100%" row-key="value" default-expand-all
        :height="tabHeight" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column prop="label" label="名称" width="295"></el-table-column>
        <el-table-column prop="value" label="ID" align="center" width="95"></el-table-column>
        <el-table-column prop="sorder" width="95" label="序号">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sorder" @change="updatesortmenu(scope.row)"></el-input>
          </template>
        </el-table-column>

      </el-table>
    </div>

  </div>
</template>

<script>
import {
  getmenutreeRequest, updatesortmenu
} from '@/api/sys_role'
export default {
  name: 'zrproducttypelist',
  data() {
    return {
      tabHeight: window.innerHeight - 300,

      name: '',
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0
    }
  },
  computed: {
    breadCrumbList() {
      return this.$route.matched
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        that.tabHeight = window.innerHeight - 300
      })()
    }
  },
  methods: {
    getList() {
      getmenutreeRequest().then(res => {
        let data = res.data
        this.adminList = data
      })
    },

    updatesortmenu(row) {
      updatesortmenu({
        Id: row.value,
        Sorder: row.sorder
      }).then(res => {
        this.onCurrentChange(0)
      })
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states
      }).then(res => {
        row.states = states
      })
    },
    toCreate() {
      this.$router.push({
        path: '/ywrecoverytype/edit',
        query: {
          id: 0
        }
      })
    },
    toEdit(id) {
      this.$router.push({
        path: '/ywrecoverytype/edit',
        query: {
          id: id
        }
      })
    },

    onDelete(id) {
      this.$confirm('此操作将删除改行, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRequest({ Id: id }).then(res => {
          if (res.code == 1) {
            this.$message({
              type: 'success',
              message: `成功`
            })
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>

</style>
